import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./Unauthorized.scss";

const Unauthorized = () => {
  return (
    <div className="page-container unauthorized-container">
      <p>Unauthorized</p>
      <Link to={"/"}>
        <Button>Login</Button>
      </Link>
    </div>
  );
};

export default Unauthorized;
