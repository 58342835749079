import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    loggedIn: false,
  },
  reducers: {
    setUserData: (state, action) => {
      state.username = action.payload;
      state.loggedIn = true;
    },
    resetUserData: (state, action) => {
      state.username = "";
      state.loggedIn = false;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
