import { Alert, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useValidate from "../../hooks/useValidate";
import "./LoginPage.scss";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { userActions } from "../../slices/user";

const LoginPage = () => {
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("loggedIn")) {
      navigate("/home");
    }
  }, []);

  const validateUsername = (val) => {
    if (val === process.env.REACT_APP_USERNAME) return true;
    else return false;
  };
  const validatePassword = (val) => {
    if (val === process.env.REACT_APP_PASSWORD) return true;
    else return false;
  };
  const {
    value: username,
    isValid: usernameValid,
    hasError: usernameError,
    valueBlurHandler: usernameBlurHandler,
    valueChangedHandler: usernameChangeHandler,
    reset: usernameReset,
  } = useValidate(validateUsername);

  const {
    value: password,
    isValid: passwordValid,
    hasError: passwordError,
    valueBlurHandler: passwordBlurHandler,
    valueChangedHandler: passwordChangeHandler,
    reset: passwordReset,
  } = useValidate(validatePassword);

  let formValid = false;

  if (usernameValid && passwordValid) formValid = true;

  const loginAction = () => {
    if (formValid) {
      //login action
      dispatch(userActions.setUserData(username));
      localStorage.setItem("loggedIn", true);
      navigate("/home");
      passwordReset();
      usernameReset();
    } else {
      setError(true);
      return;
    }
  };

  return (
    <div className="page-container login-page-container">
      {error && (
        <div className="alert-wrap">
          <Alert
            onClose={() => {
              setError(false);
            }}
            severity="error"
          >
            Unesite ispravne podatke!
          </Alert>
        </div>
      )}
      <div className="login-component">
        <TextField
          id="login-username"
          label="Username"
          variant="filled"
          error={usernameError}
          value={username}
          onChange={usernameChangeHandler}
          onBlur={usernameBlurHandler}
        />
        <TextField
          margin="normal"
          id="login-password"
          type={"password"}
          label="Lozinka"
          variant="filled"
          error={passwordError}
          value={password}
          onChange={passwordChangeHandler}
          onBlur={passwordBlurHandler}
        />
        <Button onClick={loginAction} variant="contained">
          Uloguj se
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
