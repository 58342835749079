import React from "react";
import Menu from "../../components/UI/Menu/Menu";
import "./HomeDash.scss";
import { DataGrid, GridColDef, GridApi, GridCellValue } from "@mui/x-data-grid";
import { Button } from "@mui/material";

const HomeDash = () => {
  const columns = [
    { field: "machineID", headerName: "Machine ID", width: 130 },
    { field: "url", headerName: "URL", width: 130 },
    { field: "ticket", headerName: "Ticket", width: 130 },
    {
      field: "node",
      headerName: "Node",

      width: 90,
    },
    {
      field: "vmName",
      headerName: "VmName",
      description: "This column has a value getter and is not sortable.",
      width: 160,
    },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          console.log(thisRow);

          let url = `${thisRow.url}/?console=kvm&novnc=1&vmid=${thisRow.machineID}&vmname=${thisRow.vmName}&node=${thisRow.node}&resize=off&cmd=`;

          var cookieName = "PVEAuthCookie";
          var cookieValue =
            "PVE:root@pam:63ECE4D1::Xbqml76N1aYU8M8Yi0vUvCY4fIL5rXf8jdIb0bOse5rPp9xRV9RSR85niOXNWAsFQ7i4mwy8fZUw5IvZemksBe7yHE9rwCjS5+WLMw5+5QxT3JVbp4sA+DWrnbvJN+B7mWT4Io5ztcDkAamIYrC+8/Yl94i3kQaPUMmLzDNxN0Uid22U6l6mja/qIdy+jYdgTwo3Xamvb6ntqsY9SInNSPzw79dZiy1nt1WsL0SRMuBnco9B7017PXQejRPSL4RYKprIjxfe5+tkAk4BJovWjH0wGJTKMPh45vI8tO265otZG/wAPtdrGPW5HMUmXueq3hICBZZ03mM0QxDmcbwAzw==";
          var myDate = new Date();
          myDate.setMonth(myDate.getMonth() + 12);
          document.cookie =
            cookieName +
            "=" +
            cookieValue +
            ";expires=" +
            myDate +
            ";domain=.kodio.me;path=/";

          // window.location.href = url;

          window.open(
            `https://pmx-rtb12.kodio.me:8006/?console=kvm&novnc=1&vmid=103&vmname=4027&node=pmx-rtb12&resize=off&cmd=`
          );
        };

        return (
          <Button color="success" onClick={onClick}>
            Start
          </Button>
        );
      },
    },
  ];

  const rows = [
    {
      id: 0,
      machineID: 103,
      url: "https://109.236.84.189:8006",
      ticket:
        "PVE:root@pam:63EB5BFD::pvVI62tBSQ9g2cEYGnZnOo5xkFYBL8UmiobXBTu6vuVxnIL6x6/hYgEDF3u2/ojmMQ97CGY8mjVr97jhLcGDn30RFpN+HYBMQ96YWsPp+3ANfWHMkfmWfkBDZovYpFvREiiTexSxCGd9h2fgGCvGTEmmWFykAFZZJTTvs/KoptqDu4s47Rvh3B5pVfBRCLk+NzaxW0WPd4v1/m3wG+TF5G1QyozDbhfztoQxhT/EZwAukWW1cB0bzALRWlh6fSXrs4bPdXCIkHDVvrMe+bJxTiSSm2+8k3IBaoIHSrYrbGHaIZ4zPcX1qXv9yqvh0zf7eFvxlm+xzoeIRA44oYnAPQ==",
      node: "pmx-rtb12",
      vmName: "4027",
    },
    {
      id: 1,
      machineID: 1,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 2,
      machineID: 2,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 3,
      machineID: 3,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 4,
      machineID: 4,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 5,
      machineID: 5,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 6,
      machineID: 6,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 7,
      machineID: 7,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 8,
      machineID: 8,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 9,
      machineID: 9,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 11,
      machineID: 11,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 12,
      machineID: 12,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 13,
      machineID: 13,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 14,
      machineID: 14,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 15,
      machineID: 15,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 16,
      machineID: 16,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 17,
      machineID: 17,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 18,
      machineID: 18,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
    {
      id: 19,
      machineID: 19,
      url: "Jon",
      token: 35,
      node: "node",
      vmName: "vmName",
    },
  ];
  return (
    <div className="wrapper">
      <Menu />
      <div className="page-container home-dash-container">
        <h3>Dashboard</h3>
        <div className="table-wrap" style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            // checkboxSelection
          />
        </div>
      </div>
    </div>
  );
};

export default HomeDash;
