import { Route, Routes } from "react-router";
import "./App.scss";
import ProtectedRoute from "./app/components/UI/Protected/ProtectedRoute";
import HomeDash from "./app/pages/HomeDash/HomeDash";
import LoginPage from "./app/pages/Login/LoginPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<LoginPage />} path="/" />
        <Route element={<ProtectedRoute />}>
          <Route element={<HomeDash />} path="/home" />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
