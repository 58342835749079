import { useLocation, Navigate, Outlet } from "react-router";
import { useState } from "react";
import { useSelector } from "react-redux";
import Unauthorized from "../Unauthorized/Unauthorized";

const ProtectedRoute = () => {
  const userStore = useSelector((store) => store.user);
  const [authorized, setAuthorized] = useState(userStore.loggedIn);
  const location = useLocation();

  if (authorized) {
    return <Outlet />;
  } else if (!authorized && userStore.roles.length !== 0) {
    return (
      <>
        <Unauthorized />
      </>
    );
  } else {
    return <Navigate to={"/"} state={{ from: location }} replace />;
  }
};

export default ProtectedRoute;
